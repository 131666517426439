import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

const LPRoutes = () => {
	const Dashboard = lazy(() => import("../../pages/lp/Dashboard"));
	const Reports = lazy(() => import("../../pages/Reports"));
	const PropertyDetail = lazy(() => import("../../pages/lp/PropertyDetail"));
	const InvestmentInvite = lazy(() => import("../../pages/InvestmentInvite"));
	const InvestmentDetail = lazy(
		() => import("../../pages/lp/InvestmentDetail"),
	);
	const Investments = lazy(() => import("../../pages/lp/Investments"));
	const Portfolio = lazy(() => import("../../pages/lp/Portfolio"));
	const Properties = lazy(() => import("../../pages/lp/Properties"));
	const Documents = lazy(() => import("../../Documents"));
	const Conversation = lazy(() => import("../../Conversation"));
	const FourOhFour = lazy(() => import("../../FourOhFour"));
	const InvestorManagement = lazy(
		() => import("../../pages/InvestorManagement"),
	);

	const OutlookCallback = lazy(() => import("../../pages/OutlookCallback"));
	const GoogleCallback = lazy(() => import("../../pages/GoogleCallback"));
	return (
		<Routes>
			<Route
				path="/"
				exact
				element={
					<Suspense>
						<Dashboard />
					</Suspense>
				}
			/>

			<Route
				path="/reports"
				exact
				element={
					<Suspense>
						<Reports />
					</Suspense>
				}
			/>
			<Route
				path="/property/:uid"
				element={
					<Suspense>
						<PropertyDetail />
					</Suspense>
				}
			/>
			<Route
				path="/invite/:investment_id/:investor_id?"
				exact
				element={
					<Suspense>
						<InvestmentInvite />
					</Suspense>
				}
			/>

			<Route
				exact
				path="/investor/:uid/*"
				element={
					<Suspense>
						<InvestorManagement />
					</Suspense>
				}
			/>
			<Route
				path="/investment/:uid"
				element={
					<Suspense>
						<InvestmentDetail />
					</Suspense>
				}
			/>

			<Route
				path="/investments"
				element={
					<Suspense>
						<Investments />
					</Suspense>
				}
			/>
			<Route
				path="/portfolio"
				element={
					<Suspense>
						<Portfolio />
					</Suspense>
				}
			/>
			<Route
				path="/properties"
				element={
					<Suspense>
						<Properties />
					</Suspense>
				}
			/>

			<Route
				path="/documents"
				exact
				element={
					<Suspense>
						<Documents />
					</Suspense>
				}
			/>

			<Route
				path="/conversations/*"
				element={
					<Suspense>
						<Conversation />
					</Suspense>
				}
			/>
			<Route
				path="/auth/outlook/callback"
				element={
					<Suspense>
						<OutlookCallback />
					</Suspense>
				}
			/>

			<Route
				path="/auth/google/callback"
				element={
					<Suspense>
						<GoogleCallback />
					</Suspense>
				}
			/>

			<Route
				element={
					<Suspense>
						<FourOhFour />
					</Suspense>
				}
			/>
		</Routes>
	);
};
export default LPRoutes;
