export const LOGOUT = "LOGOUT";
export const SET_USER = "SET_USER";
export const SET_CLIENT = "SET_CLIENT";
export const REDIRECT = "REDIRECT";
export const SET_CURRRENT_PAGE = "SET_CURRRENT_PAGE";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const TOGGLE_SIDEOVERLAY = "TOGGLE_SIDEOVERLAY";
export const SET_TABLE_DATA = "SET_TABLE_DATA";
export const SET_TABLE_PROPERTY = "SET_TABLE_PROPERTY";
export const SET_QUERY = "SET_QUERY";
export const SET_CURRENT_MODAL = "SET_CURRENT_MODAL";
export const SET_ACTIVE_PROPERTY = "SET_ACTIVE_PROPERTY";
export const UPDATE_MESSAGING = "UPDATE_MESSAGING";
export const UPDATE_MESSAGE_COUNT = "UPDATE_MESSAGE_COUNT";
export const SET_ACTIVE_CONVERSATION = "SET_ACTIVE_CONVERSATION";
export const SET_UNREAD_CONVERSATIONS = "SET_UNREAD_CONVERSATIONS";
export const UPDATE_DOC_DATA = "UPDATE_DOC_DATA";
export const SET_NEW_GETDATA = "SET_NEW_GETDATA";
export const SET_PAGE_NUMBER = "SET_PAGE_NUMBER";
export const SET_ENTITY = "SET_ENTITY";
export const SET_MODAL_DATA = "SET_MODAL_DATA";
export const SET_CURRENT_MANAGEMENT_ITEM = "SET_CURRENT_MANAGEMENT_ITEM";
export const SET_BREADCRUMB_DATA = "SET_BREADCRUMB_DATA";
export const SET_CURRENT_CONVERSATIONS = "SET_CURRENT_CONVERSATIONS";
export const SET_CURRENT_SIDEBAR = "SET_CURRENT_SIDEBAR";
export const SET_BACK_PATH = "SET_BACK_PATH";
export const SHOW_SMALL_SIDE_BAR = "SHOW_SMALL_SIDE_BAR";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const NOTIFICATIONS = "NOTIFICATIONS";
export const SHOW_ALERT = "SHOW_ALERT";
export const SET_CRM_FILTERS = "SET_CRM_FILTERS";
export const SET_REPORT_FILTERS = "SET_REPORT_FILTERS";
export const SET_DOCUMENT_FILTERS = "SET_DOCUMENT_FILTERS";
