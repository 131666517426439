import React, { lazy, Suspense } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Route, Routes } from "react-router-dom";
import { ApolloProvider } from "react-apollo";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createHttpLink } from "apollo-link-http";
import { ApolloLink } from "apollo-link";
import { setContext } from "apollo-link-context";
import axios from "axios";
import * as qs from "qs";
import gql from "graphql-tag";
import { onError } from "apollo-link-error";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import GPRoutes from "./components/routes/GPRoutes";
import LPRoutes from "./components/routes/LPRoutes";
import asyncComponent from "./asyncComponent";
import {
  setUser,
  setClient,
  updateMessaging,
  updateMessageCount,
  setCurrentModal,
  setEntity,
  setGetNotifications,
} from "./actionCreators";
import { CURRENTUSER } from "./helpers/gqlQueries";
import "./scss.scss";
const AsyncTopNav = asyncComponent(() => import("./components/TopNav"));

const AsyncModal = asyncComponent(() => import("./Modal"));
const AsyncSideInfoTab = asyncComponent(() => import("./SideInfoTab"));

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql/query`,
});

const PASS_VERIFICATION_MUTATION = gql`
  mutation ($code: String!, $email: String) {
    verifyCode(code: $code, email: $email) {
      token
    }
  }
`;

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

const token = sessionStorage.getItem("token");

const authLink = setContext((_, { headers }) => {
  if (token) {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  }
  return {
    headers: {
      ...headers,
    },
  };
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors && graphQLErrors.filter((e) => e).length > 0)
    graphQLErrors.map(({ message = "", status = 200 }) => {
      if (status === 401 || message === "Unauthorized") {
        if (
          (!sessionStorage.redirect && window.location.pathname !== "/") ||
          sessionStorage.redirect === "/"
        ) {
          sessionStorage.setItem(
            "redirect",
            (window.location.pathname + window.location.search).substr(1),
          );
        }

        if (
          window.location.pathname !== "/" &&
          window.location.href.indexOf("/invite/") === -1
        ) {
          window.location.href = "/";
        }
      }
      return null;
    });
});

const uploadLink = createUploadLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql/upload`,
  headers: {
    "content-type": "multipart/form-data",
  },
});
const link = ApolloLink.from([
  errorLink,
  authLink.concat(httpLink),
  authLink.concat(uploadLink),
]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  defaultOptions,
});

const Auth = lazy(() => import("./Auth"));
const InvestmentInvite = lazy(() => import("./pages/InvestmentInvite"));

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      loaded: false,
      data: { myprofile: {} },
      stripe: null,
      callInFuture: false,
      viewing_caudex_entity_id: null,
    };
    this.changeViewingAs = this.changeViewingAs.bind(this);
    this.changeViewingAsEntity = this.changeViewingAsEntity.bind(this);
  }

  componentDidMount() {
    this.getData();
    if (window.location.href.indexOf("include") > -1) {
      document.documentElement.classList.add(`app-skin-light`);
    } else if (localStorage.getItem("mode")) {
      document.documentElement.classList.add(
        `app-skin-${localStorage.getItem("mode")}`,
      );
    } else {
      document.documentElement.classList.add(`app-skin-light`);
    }
    const parsed = qs.parse(window.location.search.replace(/^\?/, ""));
    
    if (parsed.resetPassword) {
      this.props.handleModalChange("RESETPASSWORD", parsed.token);
    }
    if (parsed.verifyLogin) {
      client
        .mutate({
          mutation: PASS_VERIFICATION_MUTATION,
          variables: {
            email: localStorage.getItem("email")?atob(localStorage.getItem("email")):null,
            code: parsed.token,
          },
        })
        .then((data) => {
          if (data.data.verifyCode.token) {
            sessionStorage.setItem("token", data.data.verifyCode.token);
            this.props.handleModalChange(null);
            localStorage.removeItem("email");
            window.location.href = "/";
          }
        });
    } else if (parsed.token) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/auto-login/${parsed.token}`)
        .then((res) => {
          sessionStorage.setItem("token", res.data.token);
          if (res.data.management) {
            window.location.href = `/management/manage-entity/${res.data.entity}/overview?resetPassword=true`;
          } else if (res.redirect) {
            window.location.href = `${res.redirect}?resetPassword=true`;
          } else {
            window.location.href = "/?resetPassword=true";
          }
        });
    }
  }

  getData() {
    this.props.setClient(client);
    client
      .query({
        query: CURRENTUSER,
      })
      .then((data) => {
        if (data.data.myprofile) {
          this.props.setUser(data.data.myprofile);
          if (
            data.data.myprofile.roles &&
            data.data.myprofile.viewing_caudex_entity_id
          ) {
            const entityMatch = data.data.myprofile.roles.find(
              (role) =>
                role.caudex_entity_id ===
                data.data.myprofile.viewing_caudex_entity_id,
            );
            if (entityMatch) {
              this.props.setEntity(entityMatch);
            } else if (
              data.data.myprofile.primary_entity_name &&
              data.data.myprofile.caudex_entity_id
            ) {
              this.props.setEntity({
                caudex_entity_id: data.data.myprofile.caudex_entity_id,
                entity_name: data.data.myprofile.primary_entity_name,
                employee_role: "Fund Manager",
              });
            }
          }
          this.setupPusher(data.data.myprofile.caudex_user_id);
          this.setState({
            data: data.data,
            loaded: true,
            viewing_as: data.data.myprofile.viewing_as,
          });
        }
      });
  }

  setupPusher(caudexUserId) {
    /* eslint-disable no-undef */
    /* eslint-disable react/jsx-no-bind */
    const pusher = new Pusher("096feff06f0b8b1af970", {
      cluster: "us2",
    });
    const channel = pusher.subscribe(`channel-${caudexUserId}`);
    channel.bind(`new-message`, (data) => this.handleNewMessage(data));
    channel.bind(`new-notification`, (data) => this.handleNewNotification());
    channel.bind(`update-count`, (data) => this.updateMessageCount(data));
    /* eslint-enable no-undef */
    /* eslint-enable react/jsx-no-bind */
  }

  handleNewMessage(data) {
    this.props.updateMessaging(true);
    setTimeout(() => this.props.updateMessaging(false), 1000);
    if (data.data !== this.props.user.caudex_user_id) {
      this.updateMessageCount();
    }
  }

  handleNewNotification() {
    this.props.setGetNotifications(true);
    setTimeout(() => this.props.setGetNotifications(false), 1000);
  }

  changeViewingAs(id) {
    this.setState({ viewing_as: id });
  }

  changeViewingAsEntity(id) {
    this.setState({ viewing_caudex_entity_id: id });
  }

  updateMessageCount() {
    this.props.updateMessageCount(true);
    setTimeout(() => this.props.updateMessageCount(false), 1000);
  }

  render() {
    let navs;
    let routes;

    routes = (
      <Routes>
        <Route
          path="/"
          exact
          element={
            <Suspense>
              <Auth />
            </Suspense>
          }
        />
        <Route
          path="/invite/:investment_id/*/:investor_id?"
          element={
            <Suspense>
              <InvestmentInvite />
            </Suspense>
          }
        />
      </Routes>
    );

    if (this.props.user && this.props.user.viewing_as) {
      navs = (
        <div>
          <AsyncTopNav
            changeViewingAs={this.changeViewingAs}
            viewing_as={this.state.viewing_as}
            viewing_caudex_entity_id={this.state.viewing_caudex_entity_id}
            changeViewingAsEntity={this.changeViewingAsEntity}
          />
        </div>
      );

      if (this.props.user.viewing_as === 2) {
        routes = <GPRoutes client={client} />;
      } else if (this.props.user.viewing_as === 3) {
        routes = <LPRoutes client={client} />;
      }
    }
    return (
      <ApolloProvider client={client}>
        <div className="nav-wrapper">{navs}</div>
        <div
          id="wrapper"
          className={`nxl-container apps-container ${
            !this.props.user ? "no-sidemargin" : ""
          } ${this.props.sidebarMini ? "" : ""} 
            ${this.props.showSideOverlay ? "" : ""} `}
        >
          <div className="nxl-content">{routes}</div>
        </div>

        <AsyncModal />
        <AsyncSideInfoTab />
      </ApolloProvider>
    );
  }
}

/* eslint-disable react/forbid-prop-types */
App.propTypes = {
  setUser: PropTypes.func,
  setClient: PropTypes.func,
  user: PropTypes.any,
  sidebarMini: PropTypes.bool,
  showSideOverlay: PropTypes.bool,
  updateMessaging: PropTypes.func,
  updateMessageCount: PropTypes.func,
  handleModalChange: PropTypes.func,
  setEntity: PropTypes.func,
  setGetNotifications: PropTypes.func,
};
/* eslint-enable react/forbid-prop-types */

App.defaultProps = {
  setUser: Function,
  setClient: Function,
  user: null,
  sidebarMini: false,
  showSideOverlay: false,
  updateMessaging: Function,
  updateMessageCount: Function,
  handleModalChange: Function,
  setEntity: Function,
  setGetNotifications: Function,
};

const mapStateToProps = (state) => ({
  user: state.user,
  client: state.client,
  sidebarMini: state.sidebarMini,
  showSideOverlay: state.showSideOverlay,
});

const mapDispatchToProps = (dispatch: Function) => ({
  handleModalChange(value, data) {
    dispatch(setCurrentModal(value, data));
  },
  setUser(value) {
    dispatch(setUser(value));
  },
  setEntity(value) {
    dispatch(setEntity(value));
  },
  setClient(value) {
    dispatch(setClient(value));
  },
  updateMessaging(value) {
    dispatch(updateMessaging(value));
  },
  updateMessageCount(value) {
    dispatch(updateMessageCount(value));
  },
  setGetNotifications(value) {
    dispatch(setGetNotifications(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
