// @flow

import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

const GPRoutes = () => {
	const Dashboard = lazy(() => import("../../pages/gp/Dashboard"));
	const InvestmentInvite = lazy(() => import("../../pages/InvestmentInvite"));
	const Investments = lazy(() => import("../../pages/gp/Investments"));
	const InvestmentDetail = lazy(
		() => import("../../pages/gp/InvestmentDetail"),
	);
	const InvestorManagement = lazy(
		() => import("../../pages/InvestorManagement"),
	);
	const ContactManagement = lazy(
		() => import("../../pages/gp/ContactManagement"),
	);
	const Portfolio = lazy(() => import("../../pages/gp/Portfolio"));
	const PropertyDetail = lazy(() => import("../../pages/gp/PropertyDetail"));
	const InvestorDetail = lazy(() => import("../../pages/gp/InvestorDetail"));
	const EntityManagement = lazy(
		() => import("../../pages/gp/Management/EntityManagement"),
	);
	const PropertyManagement = lazy(
		() => import("../../pages/gp/Management/PropertyManagement"),
	);

	const CRMManagement = lazy(
		() => import("../../pages/gp/CRM/CRMManagement"),
	);
	const InvestmentManagement = lazy(
		() => import("../../pages/gp/Management/InvestmentManagement"),
	);
	const Reports = lazy(() => import("../../pages/Reports"));
	const Documents = lazy(() => import("../../pages/Documents"));
	const Conversation = lazy(() => import("../../Conversation"));
	const FourOhFour = lazy(() => import("../../FourOhFour"));
	const OutlookCallback = lazy(() => import("../../pages/OutlookCallback"));
	const GoogleCallback = lazy(() => import("../../pages/GoogleCallback"));

	return (
		<Routes>
			<Route
				path="/"
				exact
				element={
					<Suspense>
						<Dashboard />
					</Suspense>
				}
			/>
			<Route
				path="/invite/:investment_id/:uid?"
				exact
				element={
					<Suspense>
						<InvestmentInvite />
					</Suspense>
				}
			/>
			<Route
				exact
				path="/investors"
				element={
					<Suspense>
						<InvestorDetail />
					</Suspense>
				}
			/>
			<Route
				exact
				path="/crm/*"
				element={
					<Suspense>
						<CRMManagement />
					</Suspense>
				}
			/>
			<Route
				exact
				path="/investments"
				element={
					<Suspense>
						<Investments />
					</Suspense>
				}
			/>
			<Route
				exact
				path="/investment/:uid"
				element={
					<Suspense>
						<InvestmentDetail />
					</Suspense>
				}
			/>
			<Route
				exact
				path="/investor/:uid/*"
				element={
					<Suspense>
						<InvestorManagement />
					</Suspense>
				}
			/>
			<Route
				exact
				path="/contact/:uid/*"
				element={
					<Suspense>
						<ContactManagement />
					</Suspense>
				}
			/>
			<Route
				exact
				path="/portfolio"
				element={
					<Suspense>
						<Portfolio />
					</Suspense>
				}
			/>
			<Route
				path="/property/:uid"
				element={
					<Suspense>
						<PropertyDetail />
					</Suspense>
				}
			/>
			<Route
				path="/investors/:uid"
				element={
					<Suspense>
						<InvestorDetail />
					</Suspense>
				}
			/>
			<Route
				path="/management/manage-entity/:entity_id/*"
				exact
				element={
					<Suspense>
						<EntityManagement />
					</Suspense>
				}
			/>
			<Route
				path="/management/manage-property/:property_id/*"
				exact
				element={
					<Suspense>
						<PropertyManagement />
					</Suspense>
				}
			/>
			<Route
				path="/management/manage-investment/:investment_id/*"
				exact
				element={
					<Suspense>
						<InvestmentManagement />
					</Suspense>
				}
			/>
			<Route
				path="/reports"
				exact
				element={
					<Suspense>
						<Reports />
					</Suspense>
				}
			/>
			<Route
				path="/documents"
				exact
				element={
					<Suspense>
						<Documents />
					</Suspense>
				}
			/>

			<Route
				path="/conversations/*"
				element={
					<Suspense>
						<Conversation />
					</Suspense>
				}
			/>

			<Route
				path="/auth/outlook/callback"
				element={
					<Suspense>
						<OutlookCallback />
					</Suspense>
				}
			/>

			<Route
				path="/auth/google/callback"
				element={
					<Suspense>
						<GoogleCallback />
					</Suspense>
				}
			/>

			<Route
				element={
					<Suspense>
						<FourOhFour />
					</Suspense>
				}
			/>
		</Routes>
	);
};

export default GPRoutes;
