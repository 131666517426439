import {
	LOGOUT,
	SET_USER,
	REDIRECT,
	SET_CURRRENT_PAGE,
	SET_CLIENT,
	TOGGLE_SIDEBAR,
	TOGGLE_SIDEOVERLAY,
	SET_TABLE_DATA,
	SET_TABLE_PROPERTY,
	SET_QUERY,
	SET_CURRENT_MODAL,
	SET_ACTIVE_PROPERTY,
	UPDATE_MESSAGING,
	UPDATE_MESSAGE_COUNT,
	SET_ACTIVE_CONVERSATION,
	SET_UNREAD_CONVERSATIONS,
	UPDATE_DOC_DATA,
	SET_NEW_GETDATA,
	SET_PAGE_NUMBER,
	SET_ENTITY,
	SET_MODAL_DATA,
	SET_CURRENT_MANAGEMENT_ITEM,
	SET_BREADCRUMB_DATA,
	SET_CURRENT_CONVERSATIONS,
	SET_CURRENT_SIDEBAR,
	SET_BACK_PATH,
	SHOW_SMALL_SIDE_BAR,
	GET_NOTIFICATIONS,
	NOTIFICATIONS,
	SHOW_ALERT,
	SET_CRM_FILTERS,
	SET_DOCUMENT_FILTERS,
	SET_REPORT_FILTERS,
} from "./actions";

export function showAlert(name) {
	return {
		type: SHOW_ALERT,
		payload: name,
	};
}
export function setCrmFilters(filters) {
	return {
		type: SET_CRM_FILTERS,
		payload: filters,
	};
}
export function setReportFilters(filters) {
	return {
		type: SET_REPORT_FILTERS,
		payload: filters,
	};
}
export function setDocumentFilters(filters) {
	return {
		type: SET_DOCUMENT_FILTERS,
		payload: filters,
	};
}
export function setGetNotifications(bool) {
	return {
		type: GET_NOTIFICATIONS,
		payload: bool,
	};
}
export function setNotifications(value) {
	return {
		type: NOTIFICATIONS,
		payload: value,
	};
}
export function setUser(userData) {
	return {
		type: SET_USER,
		payload: userData,
	};
}

export function showSmallSideBar(page) {
	return {
		type: SHOW_SMALL_SIDE_BAR,
		payload: page,
	};
}

export function setCurrentConversations(data) {
	return {
		type: SET_CURRENT_CONVERSATIONS,
		payload: data,
	};
}

export function setCurrentManagementItem(data, list, type) {
	return {
		type: SET_CURRENT_MANAGEMENT_ITEM,
		payload: data,
		itemType: type,
		list,
	};
}

export function setEntity(entityData) {
	return {
		type: SET_ENTITY,
		payload: entityData,
	};
}

export function setBreadCrumbData(data) {
	return {
		type: SET_BREADCRUMB_DATA,
		payload: data,
	};
}

export function setUnreadMessages(unureadConversations) {
	return {
		type: SET_UNREAD_CONVERSATIONS,
		payload: unureadConversations,
	};
}

export function setNewGetData(queryString) {
	return {
		type: SET_NEW_GETDATA,
		payload: queryString,
	};
}

export function setPageNumber(number) {
	return {
		type: SET_PAGE_NUMBER,
		payload: number,
	};
}

export function setBackPage(backPath, page) {
	return {
		type: SET_BACK_PATH,
		backPath,
		page,
	};
}

export function updateData(updateDataBoolean) {
	return {
		type: UPDATE_DOC_DATA,
		payload: updateDataBoolean,
	};
}

export function setActiveConversation(activeConversation) {
	return {
		type: SET_ACTIVE_CONVERSATION,
		payload: activeConversation,
	};
}

export function updateMessageCount(unreadMessageCount) {
	return {
		type: UPDATE_MESSAGE_COUNT,
		payload: unreadMessageCount,
	};
}

export function updateMessaging(newMessage) {
	return {
		type: UPDATE_MESSAGING,
		payload: newMessage,
	};
}

export function setActiveProperty(data) {
	return {
		type: SET_ACTIVE_PROPERTY,
		payload: data,
	};
}

export function setCurrentSideBar(modal, data) {
	return {
		type: SET_CURRENT_SIDEBAR,
		payload: modal,
		data,
	};
}

export function setClient(data) {
	return {
		type: SET_CLIENT,
		payload: data,
	};
}

export function setCurrentModal(modal, data) {
	return { type: SET_CURRENT_MODAL, payload: modal, data };
}

export function setModalData(data) {
	return { type: SET_MODAL_DATA, payload: data };
}

export function setCurrentPage(page) {
	return {
		type: SET_CURRRENT_PAGE,
		payload: page,
	};
}

export function setNavigate(redirect, path) {
	return {
		type: REDIRECT,
		redirect,
		path,
	};
}

export function toggleSidebar(bool) {
	return {
		type: TOGGLE_SIDEBAR,
		payload: bool,
	};
}

export function setQuery(query) {
	return {
		type: SET_QUERY,
		payload: query,
	};
}

export function setTableData(data) {
	return {
		type: SET_TABLE_DATA,
		payload: data,
	};
}

export function setTableProperty(data) {
	return {
		type: SET_TABLE_PROPERTY,
		payload: data,
	};
}

export function toggleSideOverlay(bool) {
	return {
		type: TOGGLE_SIDEOVERLAY,
		payload: bool,
	};
}

export function logout() {
	return {
		type: LOGOUT,
		payload: null,
	};
}
