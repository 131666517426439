import {
	LOGOUT,
	SET_USER,
	SET_CURRENT_MODAL,
	REDIRECT,
	SET_CURRRENT_PAGE,
	SET_CLIENT,
	TOGGLE_SIDEBAR,
	TOGGLE_SIDEOVERLAY,
	SET_TABLE_DATA,
	SET_TABLE_PROPERTY,
	SET_QUERY,
	SET_ACTIVE_PROPERTY,
	UPDATE_MESSAGING,
	UPDATE_MESSAGE_COUNT,
	SET_ACTIVE_CONVERSATION,
	UPDATE_DOC_DATA,
	SET_UNREAD_CONVERSATIONS,
	SET_NEW_GETDATA,
	SET_PAGE_NUMBER,
	SET_ENTITY,
	SET_MODAL_DATA,
	SET_CURRENT_MANAGEMENT_ITEM,
	SET_BREADCRUMB_DATA,
	SET_CURRENT_CONVERSATIONS,
	SET_CURRENT_SIDEBAR,
	SET_BACK_PATH,
	SHOW_SMALL_SIDE_BAR,
	GET_NOTIFICATIONS,
	NOTIFICATIONS,
	SHOW_ALERT,
	SET_CRM_FILTERS,
	SET_REPORT_FILTERS,
	SET_DOCUMENT_FILTERS,
} from "./actions";

const DEFAULT_STATE = {
	currentUser: null,
	currentModal: null,
	redirectPath: null,
	redirect: false,
	currentPage: "",
	client: null,
	sidebarMini: false,
	showSideOverlay: false,
	tableProperty: null,
	tableData: null,
	activeProperty: null,
	newMessage: false,
	unreadMessageCount: 0,
	activeConversations: null,
	unreadConversations: null,
	updateDataBoolean: false,
	currentDetailPage: "",
	newGetData: "",
	pageNumber: 1,
	viewing_entity: null,
	viewingManagementItemType: null,
	viewingManagementItem: null,
	viewingManagementItemList: null,
	breadcrumbData: null,
	currentConversations: null,
	currentSideBar: null,
	backPath: null,
	backPathPage: null,
	smallSideBar: false,
	getNotifications: true,
	notifications: [],
	alertToShow: null,
	crmFilters: null,
	documentFilters: null,
	reportFilters: null,
};

const setGetNotifications = (state, action) =>
	Object.assign({}, state, {
		getNotifications: action.payload,
	});

const setCrmFilters = (state, action) =>
	Object.assign({}, state, {
		crmFilters: action.payload,
	});

const setDocumentFilters = (state, action) =>
	Object.assign({}, state, {
		documentFilters: action.payload,
	});

const setReportFilters = (state, action) =>
	Object.assign({}, state, {
		reportFilters: action.payload,
	});

const showAlert = (state, action) =>
	Object.assign({}, state, {
		alertToShow: action.payload,
	});

const setNotifications = (state, action) =>
	Object.assign({}, state, {
		notifications: action.payload,
	});

const getPathRoot = (path) => {
	path.indexOf(1);

	path.toLowerCase();

	return path.split("/")[1];
};

const getDetailPathRoot = (path) => {
	path.indexOf(1);

	path.toLowerCase();

	return path.split("/")[2];
};

const showSmallSideBar = (state, action) =>
	Object.assign({}, state, {
		smallSideBar: action.payload,
	});

const setBackPath = (state, action) =>
	Object.assign({}, state, {
		backPath: action.backPath,
		backPathPage: action.page,
	});

const setUser = (state, action) => {
	let isLoggedIn = false;
	if (action.payload.viewing_as) {
		isLoggedIn = true;
	}
	return Object.assign({}, state, {
		user: action.payload,
		isLoggedIn,
	});
};

const setCurrentManagementItem = (state, action) =>
	Object.assign({}, state, {
		viewingManagementItem: action.payload,
		viewingManagementItemType: action.itemType,
		viewingManagementItemList: action.list,
	});

const setCurrentConversations = (state, action) =>
	Object.assign({}, state, {
		currentConversations: action.payload,
	});

const setEntity = (state, action) =>
	Object.assign({}, state, {
		viewing_entity: action.payload,
	});

const setBreadCrumbData = (state, action) =>
	Object.assign({}, state, {
		breadcrumbData: action.payload,
	});

const setCurrentSidebar = (state, action) =>
	Object.assign({}, state, {
		currentSideBar: action.payload,
		sideBarData: action.data,
	});

const setCurrentModal = (state, action) => {
	if (!action.data) {
		window.history.replaceState(null, null, window.location.pathname);
	}
	return Object.assign({}, state, {
		currentModal: action.payload,
		playerVideo: action.data,
		calendlyReason: action.data,
		modalData: action.data,
	});
};

const setModalData = (state, action) => {
	if (!action.data) {
		window.history.replaceState(null, null, window.location.pathname);
	}
	return Object.assign({}, state, {
		modalData: action.data,
	});
};

const toggleSidebar = (state) =>
	Object.assign({}, state, {
		sidebarMini: !state.sidebarMini,
	});

const setPageNumber = (state, action) =>
	Object.assign({}, state, {
		pageNumber: action.payload,
	});

const toggleSideOverlay = (state) =>
	Object.assign({}, state, {
		showSideOverlay: !state.showSideOverlay,
	});

const setClient = (state, action) =>
	Object.assign({}, state, {
		client: action.payload,
	});

const setNewGetData = (state, action) =>
	Object.assign({}, state, {
		newGetData: action.payload,
	});

const setUnreadMessages = (state, action) =>
	Object.assign({}, state, {
		unreadConversations: action.payload,
	});

const updateData = (state, action) =>
	Object.assign({}, state, {
		updateDataBoolean: action.payload,
	});

const setCurrentPage = (state, action) =>
	Object.assign({}, state, {
		currentPage: getPathRoot(action.payload),
		currentDetailPage: getDetailPathRoot(action.payload),
	});

const setActiveConversation = (state, action) =>
	Object.assign({}, state, {
		activeConversation: action.payload,
	});

const setTableData = (state, action) =>
	Object.assign({}, state, {
		tableData: action.payload,
	});

const updateMessaging = (state, action) =>
	Object.assign({}, state, {
		newMessage: action.payload,
	});

const setTableProperty = (state, action) =>
	Object.assign({}, state, {
		tableProperty: action.payload,
	});

const setQuery = (state, action) =>
	Object.assign({}, state, {
		query: action.payload,
	});

const updateMessageCount = (state, action) =>
	Object.assign({}, state, {
		unreadMessageCount: action.payload,
	});

const setNavigate = (state, action) =>
	Object.assign({}, state, {
		redirect: action.redirect,
		redirectPath: action.path,
	});

const setActiveProperty = (state, action) =>
	Object.assign({}, state, { activeProperty: action.payload });

const logout = (state) =>
	Object.assign({}, state, {
		user: null,
		isLoggedIn: false,
	});

const rootReducer = (state = DEFAULT_STATE, action) => {
	switch (action.type) {
		case LOGOUT:
			return logout();
		case SET_UNREAD_CONVERSATIONS:
			return setUnreadMessages(state, action);
		case UPDATE_DOC_DATA:
			return updateData(state, action);
		case SET_ACTIVE_CONVERSATION:
			return setActiveConversation(state, action);
		case UPDATE_MESSAGE_COUNT:
			return updateMessageCount(state, action);
		case SET_USER:
			return setUser(state, action);
		case SET_ENTITY:
			return setEntity(state, action);
		case UPDATE_MESSAGING:
			return updateMessaging(state, action);
		case SET_CURRENT_MODAL:
			return setCurrentModal(state, action);
		case SET_CLIENT:
			return setClient(state, action);
		case REDIRECT:
			return setNavigate(state, action);
		case SET_CURRRENT_PAGE:
			return setCurrentPage(state, action);
		case TOGGLE_SIDEBAR:
			return toggleSidebar(state, action);
		case TOGGLE_SIDEOVERLAY:
			return toggleSideOverlay(state, action);
		case SET_TABLE_DATA:
			return setTableData(state, action);
		case SET_TABLE_PROPERTY:
			return setTableProperty(state, action);
		case SET_QUERY:
			return setQuery(state, action);
		case SET_ACTIVE_PROPERTY:
			return setActiveProperty(state, action);
		case SET_NEW_GETDATA:
			return setNewGetData(state, action);
		case SET_PAGE_NUMBER:
			return setPageNumber(state, action);
		case SET_MODAL_DATA:
			return setModalData(state, action);
		case SET_CURRENT_MANAGEMENT_ITEM:
			return setCurrentManagementItem(state, action);
		case SET_BREADCRUMB_DATA:
			return setBreadCrumbData(state, action);
		case SET_CURRENT_CONVERSATIONS:
			return setCurrentConversations(state, action);
		case SET_CURRENT_SIDEBAR:
			return setCurrentSidebar(state, action);
		case SET_BACK_PATH:
			return setBackPath(state, action);
		case SHOW_SMALL_SIDE_BAR:
			return showSmallSideBar(state, action);
		case GET_NOTIFICATIONS:
			return setGetNotifications(state, action);
		case NOTIFICATIONS:
			return setNotifications(state, action);
		case SHOW_ALERT:
			return showAlert(state, action);
		case SET_CRM_FILTERS:
			return setCrmFilters(state, action);
		case SET_DOCUMENT_FILTERS:
			return setDocumentFilters(state, action);
		case SET_REPORT_FILTERS:
			return setReportFilters(state, action);
		default:
			return state;
	}
};

export default rootReducer;
